import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EventTemplateCardData } from '@models/design-templates/event-template-card-data.model';
import { EventTemplateService } from '@services/events/event-template.service';
import { MatDialog } from '@angular/material/dialog';
import { AppRoutes } from 'app/routes';
import { finalize } from 'rxjs';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-design-template-card',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  providers: [EventTemplateService, EventService, NotificationService],
  templateUrl: './design-template-card.component.html',
  styleUrl: './design-template-card.component.scss',
})
export class DesignTemplateCardComponent {
  @Input() template!: EventTemplateCardData;
  @Input() size?: number;
  @Input() selectMode = false;
  @Input() selected = false;

  @Input() createEventView = false;

  @Input() previewOnly = false;
  @Input() noCallback = false;
  @Input() allowActions = true;

  @Input() displayShadow = false;

  eventLoading = false;

  @Output() readonly templateChosen: EventEmitter<number> =
    new EventEmitter<number>();

  @Output() readonly createWithAIChosen: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(
    private eventTemplateService: EventTemplateService,
    private eventService: EventService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private router: Router,
  ) {}

  chooseTemplate() {
    if (this.noCallback) {
      this.createEvent();
    } else {
      this.templateChosen.emit(this.template.id);
    }
  }

  async previewTemplate() {
    const { TemplatePreviewOverlayPageComponent } = await import(
      '@modules/admin/design-templates/create-template/dialogs/template-preview-overlay-page/template-preview-overlay-page.component'
    );

    this.eventTemplateService
      .get(this.template.id)
      .subscribe((eventTemplate) => {
        const dialogRef = this.dialog.open(
          TemplatePreviewOverlayPageComponent,
          {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            panelClass: 'overlay-page-preview-dialog',
            data: {
              eventTemplate: eventTemplate,
            },
            autoFocus: false,
          },
        );
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            if (result.useTemplate) {
              this.chooseTemplate();
            } else if (result.startWithAI) {
              this.createWithAIChosen.emit(true);
            }
          }
        });
      });
  }

  createEvent() {
    this.eventLoading = true;
    this.eventService
      .create({
        eventTemplateId: this.template.id,
        name: this.template.eventName,
      })
      .pipe(
        finalize(() => {
          this.eventLoading = false;
        }),
      )
      .subscribe({
        next: (event) => {
          localStorage.setItem('eventUuid', event.uuid);
          if (this.router.url.startsWith(`/${AppRoutes.Events.events}`)) {
            this.router
              .navigate(['/', AppRoutes.Events.events, event.uri], {
                queryParams: { 'create-event-success': true, 'to-edit': true },
              })
              .then(() => {
                location.reload();
              });
          } else {
            this.router.navigate(['/', AppRoutes.Events.events, event.uri], {
              queryParams: { 'create-event-success': true, 'to-edit': true },
            });
          }
        },
        error: (err) => {
          if (err && err.message) {
            this.notificationService.error(err.message);
          }
        },
      });
  }
}
